<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Detail Apoteker'">
          <template v-slot:body>
            <HrDetail :data="data" />
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import HrDetail from '@/component/human-resource/Detail.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {

  name: 'Detail',

  components: {
    Card,
    HrDetail
  },

  data() {
    return {
      data: {
        name: "Tuan Perawat Budiman",
        email: "perawat@email.com",
        address: "Pesona lebak wangi 2 Blok B2 - 1, Sepatan timur, Kab. Tangerang",
        phone: "09272892921",
        image: 'default-profile.svg'
      },
      show: true,
      imgLoaded: false
    }
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Apoteker", route: "" },
      { title: "Daftar Apoteker", route: "/pharmacist/list" },
      { title: "Detail" },
    ])
  },

}
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>